.Rooms {
  padding-top: 2rem;
  display: flex;
  justify-content: center;
}

.Rooms-column {
  text-align: left;
  max-width: 30rem;
  width: 30rem;
  padding-left: 1rem;
  padding-right: 1rem;
}

.Rooms-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Rooms-title {
  color: #4dd7b7;
}

input#avatar {
  display: none;
}

.Rooms-account {
  display: flex;
  align-items: center;
  font-weight: bold;
}

.Rooms-account-avatar {
  -webkit-app-region: no-drag;
  cursor: pointer;
  margin-right: 0.5rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
}

.Rooms-account-avatar img {
  border-radius: 50%;
  width: 2.3rem;
  height: 2.3rem;
}

.Rooms-account-avatar i {
  display: block;
  background-size: 1.7rem 1.7rem;
  width: 1.7rem;
  height: 1.7rem;
  margin: 0.3rem;
  opacity: 0.7;
}

.Rooms-account-name {
  -webkit-app-region: no-drag;
  cursor: pointer;
}

.Rooms-account button {
  margin-left: 0.5rem;
}

.Rooms-community {
  margin-bottom: 1.5rem;
}

.Rooms-community p {
  margin-top: 0;
  margin-bottom: 0.5rem;
  opacity: 0.8;
}

.Rooms-community a {
  padding: 0.1rem 0.4rem;
}

.Rooms-community a i {
  background-size: 1.5rem 1.5rem;
  width: 1.5rem;
  height: 1.5rem;
}

.Rooms-main {
  border-radius: 0.5rem;
  padding: 1.3rem;
  box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.3);
  background-color: rgba(255, 255, 255, 0.1);
}

.Rooms-main h2 {
  margin: 0;
}

.Rooms-list-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.Rooms-empty {
  opacity: 0.5;
}

.Rooms-item {
  border-radius: 0.5rem;
  padding: 0.7rem;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3);
  background-color: rgba(255, 255, 255, 0.1);
}

.Rooms-item:not(:last-of-type) {
  margin-bottom: 1rem;
}

.Rooms-item-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.Rooms-online-count {
  font-size: 0.8rem;
  font-weight: bold;
}

.Rooms-names {
  margin-top: 0.4rem;
  font-size: 0.9rem;
}

.Rooms-item-buttons button:not(:first-of-type) {
  margin-left: 0.5rem;
}

@media only screen and (max-width: 500px) {
  .Rooms-item {
    padding: 0.3rem;
  }

  .Rooms-main {
    padding: 0.6rem;
  }
}
