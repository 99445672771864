.NewGroup-field {
  margin-bottom: 1rem;
}

.NewGroup-field input {
  min-width: 12rem;
}

.NewGroup-label {
  display: block;
  font-size: 0.9rem;
}

.NewGroup-color {
  width: 1.5rem;
  height: 1.5rem;
  cursor: pointer;
  box-shadow: 2px 5px 15px rgba(0, 0, 0, 0.5);
}

.github-picker {
  border: none !important;
  background-color: rgba(0, 0, 0, 0.2) !important;
}
