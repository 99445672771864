input[type='text'],
input[type='email'],
input[type='password'],
textarea {
  border: 1px solid rgba(92, 255, 217, 0.4);
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 0.4rem;
  font-size: 1rem;
  padding: 0.2rem 0.5rem;
  color: #cacaca;
  box-sizing: border-box;
  width: 100%;
  max-width: 100%;
  font-family: inherit;
  font-size: inherit;
}

input[type='text']:hover,
input[type='email']:hover,
input[type='password']:hover,
textarea:hover {
  border: 1px solid rgba(92, 255, 217, 0.6);
  background-color: rgba(0, 0, 0, 0.4);
}

input[type='text']:active,
input[type='email']:active,
input[type='password']:active,
textarea:active {
  background-color: rgba(0, 0, 0, 0.8);
}

input[type='text']:focus,
input[type='email']:focus,
input[type='password']:focus,
textarea:focus {
  outline: none;
  box-shadow: none;
  background-color: rgba(0, 0, 0, 0.8);
  border: 1px solid rgba(92, 255, 217, 0.8);
}

input[type='text']:disabled,
input[type='email']:disabled,
input[type='password']:disabled,
textarea:disabled {
  opacity: 0.5;
}
