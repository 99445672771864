.RemoveRoom-field {
  margin-bottom: 1rem;
}

.RemoveRoom-field input {
  min-width: 12rem;
}

.RemoveRoom-label {
  display: block;
  font-size: 0.9rem;
}
