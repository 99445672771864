.Status {
  position: absolute;
  top: 0.5rem;
  left: 0.5rem;
  color: #4dd7b7;
  font-weight: bolder;
}

.Status-electron {
  top: 2.5rem;
}
