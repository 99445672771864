.link {
  text-decoration: underline;
  opacity: 0.6;
  cursor: pointer;
  color: inherit;
  background-color: transparent;
  border: none;
  padding: 0;
}

.link:hover {
  color: rgb(71, 213, 180);
  opacity: 1;
}

.link:active {
  color: rgb(130, 245, 218);
  opacity: 1;
  outline: none;
}

.link:focus {
  color: rgb(130, 245, 218);
  opacity: 1;
  outline: none;
}
