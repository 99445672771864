.ScreenPicker-container {
  display: grid;
  grid-template-columns: 1fr 1fr;

  /* 1px margin ensures selected outline shows on all sides. */
  margin: 1px;
}

.ScreenPicker-loading {
  width: 15rem;
  height: 6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.5;
}

.ScreenPicker-source {
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  cursor: pointer;
}

.ScreenPicker-source:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.ScreenPicker-selected {
  outline: 1px solid rgb(71, 213, 180);
  background-color: rgba(255, 255, 255, 0.1);
}

.ScreenPicker-source-title {
  margin-bottom: 0.5rem;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  /* 150px is the default thumbnail size. */
  width: 150px;
}
