.Feedback-field {
  margin-bottom: 1rem;
}

.Feedback-field input {
  min-width: 12rem;
}

.Feedback-label {
  display: block;
  font-size: 0.9rem;
}
