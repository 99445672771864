.Landing {
}

.Landing-background-middle {
  background-image: url('./background-middle.svg');
  background-repeat: no-repeat;
  background-size: cover;
}

.Landing-background-top {
  background-image: url('./background-top.svg');
  background-repeat: no-repeat;
  background-size: 100% auto;
  display: flex;
  justify-content: center;
}

.Landing-content {
  max-width: 90rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Landing-header {
  padding-top: 1rem;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-bottom: 2rem;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Landing-header .Landing-logo {
  display: flex;
  align-items: center;
  color: rgb(77, 239, 201);
  font-size: 3rem;
  margin: 0;
}

.Landing-header .Landing-logo i {
  filter: brightness(0) saturate(100%) invert(82%) sepia(61%) saturate(438%) hue-rotate(98deg)
    brightness(95%) contrast(96%);
  margin-right: 0.5rem;
  background-size: 3rem 3rem;
  width: 3rem;
  height: 3rem;
}

.Landing-header .Landing-auth > button {
  margin-left: 0.5rem;
  font-size: 1.3rem;
  padding: 0.6rem 0.8rem;
}

.Landing-header .Landing-auth > button::before {
  left: 3px;
  right: 3px;
  top: 3px;
  bottom: 3px;
}

.Landing-header .Landing-auth > a {
  margin-left: 0.5rem;
  font-size: 1.3rem;
  padding: 0.6rem 0.8rem;
}

.Landing-header .Landing-auth > a::before {
  left: 3px;
  right: 3px;
  top: 3px;
  bottom: 3px;
}

.Landing-hero {
  margin-top: 4rem;
  margin-bottom: 5rem;
  padding-left: 5rem;
  padding-right: 5rem;
  text-align: left;
  display: flex;
  flex-wrap: wrap-reverse;
  align-items: center;
  justify-content: center;
}

.Landing-hero .Landing-hero-col {
  display: flex;
  justify-content: center;
  max-width: 36rem;
}

.Landing-hero .Landing-title {
  font-size: 3.7rem;
  line-height: 3.4rem;
  margin-top: 1rem;
  margin-bottom: 0;
}

.Landing-hero .Landing-description {
  margin-top: 1rem;
  font-size: 1.4rem;
}

.Landing-beta {
  margin-left: 1rem;
  margin-right: 1rem;
  max-width: 50rem;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 2rem;
  background-color: rgba(255, 255, 255, 0.2);
  box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.3);
  border-radius: 1rem;
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.Landing-beta-title {
  display: flex;
  color: rgb(77, 239, 201);
  font-size: 1.8rem;
  font-weight: bold;
}

.Landing-beta-title i {
  filter: brightness(0) saturate(100%) invert(82%) sepia(61%) saturate(438%) hue-rotate(98deg)
    brightness(95%) contrast(96%);
}

.Landing-beta p {
  text-align: left;
  color: #fff;
  font-size: 1.2rem;
  margin-right: 1rem;
  margin-top: 0;
}

.Landing-beta-bold {
  font-weight: bold;
}

.Landing-beta button {
  padding: 0.8rem 1rem;
  font-size: 1.1rem;
  font-weight: bolder;
}

.Landing-beta-footer {
  max-width: 50rem;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 5rem;
}

.Landing-beta-footer .Landing-beta-title {
  display: flex;
  align-items: center;
  font-size: 2.8rem;
}

.Landing-beta-footer p {
  font-size: 1.4rem;
}

.Landing-beta-footer button {
  padding: 0.8rem 1rem;
  font-size: 1.1rem;
  font-weight: bolder;
}

.Landing-download {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding-top: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 1rem;
}

.Landing-download p {
  text-align: left;
  color: #333;
  font-size: 1.2rem;
  font-weight: bold;
  margin-right: 1rem;
}

.Landing-download a.btn-primary {
  font-size: 1.3rem;
}

.Landing-demo-main-title {
  font-size: 2.4rem;
}

.Landing-demo {
  text-align: left;
  padding-top: 2rem;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-bottom: 0;
  margin-top: 3rem;
}

.Landing-demo .Landing-demo-title {
  font-size: 2.4rem;
}

.Landing-demo-row {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 4rem;
  box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.3);
  border-radius: 1rem;
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.Landing-demo-row:nth-child(even) {
  flex-wrap: wrap-reverse;
}

.Landing-demo-text-col {
  padding-top: 0;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-bottom: 2rem;
  text-align: left;
  flex: 2;
}

.Landing-demo-video-col {
  padding: 2rem;
  min-width: 25rem;
  max-width: 50rem;
  display: flex;
  flex-direction: column;
  flex: 3;
}

.Landing-demo-video-col video {
  background-color: black;
  border-radius: 1rem;
  max-width: 100%;
  box-shadow: 0 0 35px rgba(0, 0, 0, 0.5);
  outline: 0;
}

.Landing-demo-title {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.Landing-demo-description {
  font-size: 1.4rem;
}

@media only screen and (max-width: 900px) {
  .Landing-header {
    padding: 1rem;
  }

  .Landing-header .Landing-logo {
    font-size: 2em;
  }

  .Landing-header .Landing-logo i {
    margin-right: 0.5rem;
    background-size: 2.5rem 2.5rem;
    width: 2.5rem;
    height: 2.5rem;
  }

  .Landing-hero {
    padding-top: 0;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    padding-bottom: 0;
    margin-top: 0;
    margin-bottom: 1rem;
    text-align: center;
    flex-direction: column-reverse;
  }

  .Landing-hero .Landing-title {
    font-size: 2.1rem;
    line-height: 2rem;
    margin-bottom: 1rem;
  }

  .Landing-hero .Landing-description {
    margin-top: 0.5rem;
    font-size: 1.2rem;
  }

  .Landing-header .Landing-auth > button {
    margin-left: 0.5rem;
    font-size: 1rem;
    padding: 0.4rem 0.6rem;
  }

  .Landing-header .Landing-auth > button::before {
    left: 2px;
    right: 2px;
    top: 2px;
    bottom: 2px;
  }

  .Landing-header .Landing-auth > a {
    margin-left: 0.5rem;
    font-size: 1rem;
    padding: 0.4rem 0.6rem;
  }

  .Landing-header .Landing-auth > a::before {
    left: 2px;
    right: 2px;
    top: 2px;
    bottom: 2px;
  }

  .Landing-beta {
    padding: 1rem;
  }

  .Landing-beta p {
    font-size: 1.1rem;
  }

  .Landing-beta-footer .Landing-beta-title {
    font-size: 2rem;
  }

  .Landing-beta-footer p {
    padding-left: 1rem;
    padding-right: 1rem;
    font-size: 1.2rem;
  }

  .Landing-download {
    padding-top: 0rem;
  }

  .Landing-download p {
    flex-basis: 100%;
    font-size: 1rem;
  }

  .Landing-download a.btn-primary {
    font-size: 1rem;
  }

  .Landing-demo-main-title {
    margin-top: 1.5rem;
    margin-bottom: 0.5rem;
  }

  .Landing-demo {
    margin-top: 0;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .Landing-demo-row {
    margin-bottom: 2rem;
  }

  .Landing-demo-text-col {
    flex-basis: 100%;
    padding: 1rem;
    min-width: 0;
  }

  .Landing-demo .Landing-demo-video-col {
    flex-basis: 100%;
    padding: 0.5rem;
    min-width: 0;
  }

  .Landing-demo-text-col .Landing-demo-title {
    font-size: 1.6rem;
    margin-top: 0;
    margin-bottom: 0.5rem;
  }

  .Landing-demo-description {
    font-size: 1.1rem;
  }
}
