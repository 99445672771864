select {
  border: 1px solid rgba(92, 255, 217, 0.4);
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 0.4rem;
  font-size: 1rem;
  padding: 0.2rem 0.2rem;
  color: #cacaca;
  box-sizing: border-box;
  width: 100%;
}

select:hover {
  border: 1px solid rgba(92, 255, 217, 0.6);
  background-color: rgba(0, 0, 0, 0.4);
}

select:active {
  background-color: rgba(0, 0, 0, 0.8);
}

select:focus {
  outline: none;
  box-shadow: none;
  background-color: rgba(0, 0, 0, 0.8);
  border: 1px solid rgba(92, 255, 217, 0.8);
}

select:disabled {
  opacity: 0.5;
}
