.Login {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.Login-column {
  max-width: 30rem;
  padding-left: 1rem;
  padding-right: 1rem;
}

.Login-title {
  color: #4dd7b7;
  font-size: 2.5rem;
  margin-bottom: 0;
}

.Login-subtitle {
  margin-top: 0;
  font-size: 0.85rem;
  margin-bottom: 1rem;
}

.Login-forms {
  text-align: left;
}

.Login-button {
  margin-right: 0.5rem;
}

.Login-label {
  display: block;
}

.Login-about {
  text-align: left;
}

.Login-about p {
  opacity: 0.6;
}
