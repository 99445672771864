.ConnectDiscord-description {
  opacity: 0.6;
}

.ConnectDiscord-invite-button {
  margin-top: 0.2rem;
}

.ConnectDiscord-command-container {
  display: flex;
  align-items: center;
}

.ConnectDiscord-command-container i {
  background-size: 1.6rem 1.6rem;
  min-width: 1.6rem;
  width: 1.6rem;
  height: 1.6rem;
  cursor: pointer;
}

.ConnectDiscord-command-container i:not(:hover) {
  filter: invert(44%) sepia(59%) saturate(408%) hue-rotate(116deg) brightness(98%) contrast(92%);
}

.ConnectDiscord-code-container {
  margin-left: 0.5rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

code {
  margin: 0;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0.6;
  border-radius: 0.4rem;
  padding: 0.1rem 0.3rem;
}
