.Bar-desktop {
  -webkit-app-region: drag;
  position: absolute;
  bottom: 0.5rem;
  left: 0.5rem;
  right: 0.5rem;
}

.Bar-desktop-header {
  position: absolute;
  bottom: 3.5rem;
  left: 0.5rem;
}

.Bar-desktop-header button {
  margin-right: 0.5rem;
}

.Bar-desktop-main {
  border-radius: 4px;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  height: 3rem;
  justify-content: space-between;
}

.Bar-desktop-main::before {
  content: '';
  z-index: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--background-gradient);
  background-repeat: no-repeat;
  background-attachment: fixed;
  border-radius: 4px;
}

.Bar-desktop-main::after {
  content: '';
  z-index: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 4px;
}

.Bar-mobile-closed {
  position: absolute;
  bottom: 0.5rem;
  left: 0;
  right: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Bar-mobile-open {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.Bar-mobile-menu-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem;
  box-sizing: border-box;
  width: 100%;
}

.Bar-mobile-buttons {
  align-self: stretch;
}

.Bar-mobile-row {
  position: relative;
  display: flex;
  justify-content: space-between;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  height: 3rem;
  background-color: rgba(255, 255, 255, 0.1);
  align-items: center;
}

.Bar-mobile-row::before {
  content: '';
  z-index: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--background-gradient);
  background-repeat: no-repeat;
  background-attachment: fixed;
  border-radius: 4px;
}

.Bar-mobile-row::after {
  content: '';
  z-index: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 4px;
}

.Bar-mobile-row:first-of-type::after {
  background-color: rgba(255, 255, 255, 0.15);
}

.Bar-mobile-row-label {
  text-align: left;
  opacity: 0.5;
}

.Bar-mobile-open button:not(:first-of-type) {
  margin-left: 0.3rem;
}

.Bar-mobile-buttons .Bar-mobile-row button {
  font-size: 0.9rem;
  white-space: nowrap;
}

.Bar-group-label {
  opacity: 0.8;
  margin-right: auto;
}

.Bar-buttons {
  display: flex;
  align-items: center;
}

.Bar-button:not(:first-of-type) {
  margin-left: 0.6rem;
}

@media only screen and (max-width: 600px) {
  .Bar button {
    padding: 0.1rem 0.2rem;
  }
}

@media only screen and (max-width: 1000px) {
  .Bar-desktop-only {
    display: none;
  }

  .Bar-bottom {
    justify-content: end;
  }
}

@media only screen and (min-width: 1001px) {
  .Bar-mobile-only {
    display: none;
  }
}
