input[type='range'] {
  appearance: none;
  background-color: transparent;
  cursor: pointer;
}

input[type='range']::-webkit-slider-runnable-track {
  position: relative;
  height: 0.4rem;
  background-color: rgb(139, 41, 102);
  border-radius: 0.4rem;
}

input[type='range']::-moz-range-track {
  position: relative;
  height: 0.4rem;
  background-color: rgb(139, 41, 102);
  border-radius: 0.4rem;
}

input[type='range']::-webkit-slider-thumb {
  appearance: none;
  position: relative;
  background-color: rgb(90, 185, 163);
  box-shadow: 0 0 5px rgba(0, 0, 0, 1);
  border-radius: 50%;
  width: 1rem;
  height: 1rem;
  top: 50%;
  translate: 0 -50%;
}

input[type='range']::-moz-range-thumb {
  border: none;
  appearance: none;
  position: relative;
  background-color: rgb(90, 185, 163);
  box-shadow: 0 0 5px rgba(0, 0, 0, 1);
  border-radius: 50%;
  width: 1rem;
  height: 1rem;
}
