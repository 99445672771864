.Room {
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.Room-canvas-container {
  background: var(--background-gradient);

  /* Avoid having context menu appear on mobile */
  user-select: none !important;
  -webkit-user-select: none !important;
  -webkit-touch-callout: none !important;
  width: 100%;
  height: 100%;
}

canvas {
  display: block;
  -webkit-app-region: no-drag;
}

/* Prevent scrollbar from making canvas smaller */
canvas::-webkit-scrollbar {
  width: 0;
}
