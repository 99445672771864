.NewRoom-field {
  margin-bottom: 1rem;
}

.NewRoom-field input {
  min-width: 12rem;
}

.NewRoom-label {
  display: block;
  font-size: 0.9rem;
}
