.Grid {
  position: absolute;
  top: 2rem;
  left: 0;
  right: 0;
  bottom: 54px;
  pointer-events: none;
  overflow: hidden;
}

@media only screen and (max-width: 1000px) {
  .Grid {
    bottom: 94px;
  }
}

.Grid-hidden {
  visibility: hidden;
}

.Grid .react-grid-item {
  pointer-events: all;
  background-color: rgba(0, 0, 0, 0.2);
}

.Grid-no-drag .react-grid-item {
  -webkit-app-region: no-drag;
}

.Grid .react-grid-item > .react-resizable-handle::after {
  border-right: 2px solid #5cffd9 !important;
  border-bottom: 2px solid #5cffd9 !important;
  opacity: 0.2;
}

.Grid .react-grid-item:hover > .react-resizable-handle::after {
  border-right: 2px solid #5cffd9 !important;
  border-bottom: 2px solid #5cffd9 !important;
  opacity: 0.8;
}

.Grid .react-grid-item.react-grid-placeholder {
  opacity: 0.4 !important;
  border: 1px solid #e65398 !important;
  background-color: rgba(0, 0, 0, 0.4) !important;
  box-sizing: border-box;
}

/* Used for preventing initial animation */
.Grid .react-grid-layout {
  visibility: hidden;
}

.Grid .loaded.react-grid-layout:not(.Grid-hidden) {
  visibility: visible;
}

.Grid .react-grid-item.cssTransforms {
  transition-property: none;
}

.Grid .loaded .react-grid-item.cssTransforms {
  transition-property: transform;
}
