.App {
  text-align: center;
  color: white;
}

.App-loading {
  z-index: -10;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  opacity: 0.7;
  pointer-events: none;
}
