:root {
  --background-gradient: radial-gradient(
      circle at 50% 100%,
      rgb(1, 60, 45) 0%,
      rgba(0, 0, 0, 0) 85%
    ),
    radial-gradient(circle at 0% 0%, rgba(75, 6, 117, 1) 0%, rgba(0, 0, 0, 0) 85%),
    radial-gradient(circle at 100% 0%, rgb(165, 26, 112) 0%, rgba(0, 0, 0, 0) 85%), black;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color-scheme: dark;
  -webkit-app-region: drag;
  background: var(--background-gradient);
  background-repeat: no-repeat;
}

body::before {
  z-index: -1000;
  content: '';
  width: 100%;
  height: 100%;
  position: fixed;
  background: var(--background-gradient);
  background-repeat: no-repeat;
}

html {
  scrollbar-color: rgba(255, 255, 255, 0.4) rgba(0, 0, 0, 0.9);
  scrollbar-width: thin;
}

button,
input {
  -webkit-app-region: no-drag;
}

.title-bar {
  position: absolute;
  top: 0;
  background-color: rgba(255, 255, 255, 0.1);
  box-sizing: border-box;
  width: 100%;
  height: 34px;
  -webkit-app-region: drag;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.grab {
  cursor: grab;
}

.grabbing {
  cursor: grabbing;
}
