.ChatBox {
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: flex-start;
  overflow: hidden;
}

.ChatBox-dragging {
  z-index: 500;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.ChatBox-dragging i {
  background-size: 10rem 10rem;
  width: 10rem;
  height: 10rem;
}

.ChatBox-messages {
  flex-grow: 1;
  box-sizing: border-box;
  width: 100%;
  margin-top: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  text-align: left;
  overflow-y: auto;
}

.ChatBox-message:not(:first-of-type) {
  margin-top: 0.5rem;
}

.ChatBox-message {
  word-wrap: break-word;
}

.ChatBox-image-message img {
  display: block;
  max-height: 200px;
  max-width: 100%;
}

.ChatBox-form {
  display: flex;
  padding: 1rem;
  width: 100%;
  box-sizing: border-box;
  align-items: center;
}

.ChatBox-form i {
  background-size: 1.6rem 1.6rem;
  min-width: 1.6rem;
  width: 1.6rem;
  height: 1.6rem;
  cursor: pointer;
}

.ChatBox-form i:not(:hover) {
  filter: invert(44%) sepia(59%) saturate(408%) hue-rotate(116deg) brightness(98%) contrast(92%);
}

.ChatBox-form input[type='file'] {
  display: none;
}

.ChatBox-file-name {
  width: 100%;
  display: flex;
  align-items: center;
  opacity: 0.5;
  min-width: 0;
}

.ChatBox-file-name span {
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ChatBox-form input[type='submit'] {
  margin-left: 0.2rem;
}

.ChatBox-error {
  margin-top: -1rem;
  margin-left: 1rem;
}
