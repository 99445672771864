.error {
  font-size: 0.8rem;
  font-weight: bold;
  color: rgb(203, 99, 163);
}

.success {
  font-size: 0.8rem;
  font-weight: bold;
  color: rgb(90, 176, 156);
}
