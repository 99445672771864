.Popup {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.8);
  padding: 0.3rem;
  border-radius: 4px;
  box-shadow: 2px 5px 5px black;
  text-align: left;
  font-size: 0.9rem;
  z-index: 100;
  overflow: hidden;
}

.Popup-setting:not(:first-of-type) {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.Popup-setting {
  padding: 5px;
}
