.Invite-field {
  margin-bottom: 1rem;
}

.Invite-field input {
  min-width: 12rem;
}

.Invite-label {
  display: block;
  font-size: 0.9rem;
}

.Invite-link-description {
  opacity: 0.7;
  margin-top: 1.5rem;
  margin-bottom: 0;
}

.Invite-link-container {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
}

.Invite-link-container i {
  background-size: 1.6rem 1.6rem;
  min-width: 1.6rem;
  width: 1.6rem;
  height: 1.6rem;
  cursor: pointer;
}

.Invite-link-container i:not(:hover) {
  filter: invert(44%) sepia(59%) saturate(408%) hue-rotate(116deg) brightness(98%) contrast(92%);
}

.Invite-code-container {
  margin-left: 0.5rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  overflow-x: auto;
}

code {
  margin: 0;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0.6;
  white-space: nowrap;
  border-radius: 0.4rem;
  padding: 0.1rem 0.3rem;
}
