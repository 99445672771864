/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 10px 10px rgba(255, 255, 255, 0.3);
  border: solid 3px transparent;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  box-shadow: inset 0 0 10px 10px rgba(255, 255, 255, 0.5);
}

* {
  scrollbar-color: rgba(255, 255, 255, 0.3) transparent;
  scrollbar-width: thin;
}
