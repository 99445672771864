.Inactive {
  padding-top: 2rem;
  display: flex;
  justify-content: center;
}

.Inactive-column {
  text-align: left;
  max-width: 30rem;
  width: 30rem;
  padding-left: 1rem;
  padding-right: 1rem;
}

.Inactive-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Inactive-title {
  color: #4dd7b7;
}

input#avatar {
  display: none;
}

.Inactive-account {
  display: flex;
  align-items: center;
  font-weight: bold;
}

.Inactive-account-avatar {
  -webkit-app-region: no-drag;
  margin-right: 0.5rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
}

.Inactive-account-avatar img {
  border-radius: 50%;
  width: 2.3rem;
  height: 2.3rem;
}

.Inactive-account-avatar i {
  display: block;
  background-size: 1.7rem 1.7rem;
  width: 1.7rem;
  height: 1.7rem;
  margin: 0.3rem;
  opacity: 0.7;
}

.Inactive-account-name {
  -webkit-app-region: no-drag;
}

.Inactive-account button {
  margin-left: 0.5rem;
}

.Inactive-main {
  border-radius: 0.5rem;
  padding: 1.3rem;
  box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.3);
  background-color: rgba(255, 255, 255, 0.1);
}

.Inactive-main h2 {
  opacity: 0.7;
  margin: 0;
}
