.Greeting {
}

.Greeting svg {
  width: 20rem;
  height: 20rem;
}

.Greeting svg text {
  font-size: 0.29rem;
  font-family: Arial, sans-serif;
}

@media only screen and (max-width: 900px) {
  .Greeting svg {
    width: 16rem;
    height: 16rem;
  }
}
