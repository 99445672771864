.Subtitle {
  margin-top: 0;
  font-size: 1.1rem;
  margin-bottom: 0;
  font-weight: bolder;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.Subtitle-part {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Subtitle-part:not(:last-of-type) {
  margin-right: 1rem;
}

.Subtitle-part i {
  background-size: 1.75rem 1.75rem;
  width: 1.75rem;
  height: 1.75rem;
}

.Subtitle-video i,
.Subtitle-text i,
.Subtitle-screen i {
  margin-right: 0.2rem;
}

.Subtitle-voice {
  /* animation: fade-voice 5s infinite; */
}

.Subtitle-video {
  /* animation: fade-video 5s infinite; */
}

.Subtitle-text {
  /* animation: fade-text 5s infinite; */
}

.Subtitle-screen {
  /* animation: fade-screen 5s infinite; */
}

@keyframes fade-voice {
  0% {
    opacity: 0;
  }
  0% {
    opacity: 0;
  }
  80% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fade-video {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 0;
  }
  80% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fade-text {
  0% {
    opacity: 0;
  }
  40% {
    opacity: 0;
  }
  80% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fade-screen {
  0% {
    opacity: 0;
  }
  60% {
    opacity: 0;
  }
  80% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@media only screen and (max-width: 900px) {
  .Subtitle {
    font-size: 1rem;
    justify-content: center;
  }
}
