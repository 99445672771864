.Updater-progress-bar-container {
  margin-top: 1rem;
  width: 100%;
  height: 0.5rem;
  background-color: rgba(0, 0, 0, 0.4);
}

.Updater-progress-bar {
  height: 0.5rem;
  background-color: rgba(255, 255, 255, 0.4);
}
