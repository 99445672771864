/* ORDINARY */

.btn {
  position: relative;
  border: 1px solid transparent;
  background-color: rgba(255, 255, 255, 0.1);
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  font-size: 1rem;
  padding: 0.3rem 0.5rem;
  color: #ccc;
  z-index: 1;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  line-height: 1rem;
  text-decoration: none;
}

.btn:not(:disabled):hover {
  background-color: rgba(255, 255, 255, 0.2);
  box-shadow: 0px 0px 20px rgba(101, 6, 69, 0.5);
}

.btn:not(:disabled):active {
  background-color: rgba(255, 255, 255, 0.05);
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.8);
}

.btn:not(:disabled):focus {
  outline: none;
  border: 1px solid rgba(0, 157, 120, 0.5);
}

.btn:disabled {
  cursor: default;
  opacity: 0.5;
}

/* SECONDARY */

.btn-secondary {
  position: relative;
  border: none;
  background: linear-gradient(162deg, rgba(0, 157, 120, 1) 0%, rgba(198, 14, 147, 1) 100%);
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.7);
  border-radius: 4px;
  font-size: 1rem;
  color: #eee;
  z-index: 1;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  line-height: 1rem;
  text-decoration: none;

  /* Add 1px to make height same as primary button */
  padding: calc(0.3rem + 1px) 0.5rem;
}

.btn-secondary::before {
  content: '';
  position: absolute;
  left: 1px;
  right: 1px;
  top: 1px;
  bottom: 1px;
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: -1;
  transition: 200ms;
}

.btn-secondary:not(:disabled):hover::before {
  background-color: rgba(0, 0, 0, 0.6);
}

.btn-secondary:not(:disabled):active::before {
  background-color: rgba(0, 0, 0, 0.5);
}

.btn-secondary:not(:disabled):focus {
  outline: none;
  border: none;
}

.btn-secondary:not(:disabled):focus::before {
  background-color: rgba(0, 0, 0, 0.5);
}

.btn-secondary:disabled {
  cursor: default;
  opacity: 0.5;
}

/* PRIMARY */

.btn-primary {
  position: relative;
  border: 1px solid rgb(71, 213, 180);
  background: linear-gradient(162deg, rgb(71, 213, 180) 0%, rgb(6, 142, 110) 100%);
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.7);
  border-radius: 4px;
  font-size: 1rem;
  padding: 0.3rem 0.5rem;
  color: rgb(9, 39, 32);
  display: inline-flex;
  align-items: center;
  line-height: 1rem;
  cursor: pointer;
  text-decoration: none;
}

.btn-primary:not(:disabled):hover {
  background: none;
  background-color: rgb(56, 185, 155);
}

.btn-primary:not(:disabled):active {
  background: none;
  background-color: rgb(75, 214, 182) !important;
}

.btn-primary:not(:disabled):focus {
  outline: none;
  background: none;
  background-color: #56e3c2;
}

.btn-primary:disabled {
  cursor: default;
  opacity: 0.5;
}
