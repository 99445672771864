.KeyBindings-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.75rem;
}

.KeyBindings-item:not(:last-of-type) {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.KeyBindings-item button {
  font-size: 0.9rem;
  padding: 0.2rem 0.4rem;
}

.KeyBindings-name {
  cursor: pointer;
}

.KeyBindings-disabled span,
.KeyBindings-disabled button {
  opacity: 0.3;
}

.KeyBindings-enabled {
  margin-right: 0.75rem;
}
