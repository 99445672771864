.UploadAvatar-preview-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
  overflow: hidden;
}

.UploadAvatar-preview {
  max-width: 20rem;
  max-height: 20rem;
}

.UploadAvatar-region {
  position: absolute;
  border-radius: 50%;
  box-shadow: 0 0 0 99999px rgba(0, 0, 0, 0.7);
}
