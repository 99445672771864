.Settings-setting {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Settings-subsetting {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.9rem;
  margin-top: 0.5rem;
}

.Settings-subsetting span {
  opacity: 0.5;
}

.Settings-subsetting button {
  padding: 0.2rem 0.4rem;
}

.Settings-setting:not(:first-of-type) {
  margin-top: 1rem;
}

.Settings-label {
  font-size: 0.9rem;
  margin-right: 1rem;
}

.Settings-select {
  max-width: 10rem;
}

.Settings-test-button {
  margin-left: 0.5rem;
}
