.Modal {
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Modal::before {
  content: '';
  z-index: -2;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--background-gradient);
  background-repeat: no-repeat;
  background-attachment: fixed;
  opacity: 0.8;
}

.Modal-content::before {
  content: '';
  z-index: -2;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--background-gradient);
  background-repeat: no-repeat;
  background-attachment: fixed;
  border-radius: 10px;
}

.Modal-content::after {
  content: '';
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
}

.Modal-content {
  position: relative;
  display: flex;
  border-radius: 10px;
  padding: 1rem;
  text-align: start;
  max-width: 25rem;
  margin: 1rem;
  max-height: calc(100vh - 4rem);
  flex-direction: column;
  filter: drop-shadow(10px 10px 40px rgba(0, 0, 0, 1));
}

.Modal-header {
  display: flex;
  justify-content: space-between;
  font-size: 1.3rem;
  font-weight: bold;
  color: #aaaaaa;
  color: #5cffd9;
}

.Modal-body {
  margin-top: 1rem;
  overflow-y: auto;
  overflow-x: hidden;
}

.Modal-close-button {
  margin-left: 1rem;
}

.Modal-description {
  margin-top: 0.5rem;
  opacity: 0.7;
}

.Modal-footer {
  margin-top: 1rem;
}
