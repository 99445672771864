/* 
  These icons are from the Plumpy set over at icons8:
    https://icons8.com/icons/plumpy

  Download the SVG, black, 48x48 with "Simplified SVG" selected.
*/

button i,
a i {
  margin-right: 0.2rem;
}

.icon {
  display: block;
  background-size: 1.3rem 1.3rem;
  width: 1.3rem;
  height: 1.3rem;
  filter: brightness(0) saturate(100%) invert(80%) sepia(9%) saturate(2427%) hue-rotate(112deg)
    brightness(91%) contrast(90%);
}

.icon-dark {
  filter: none;
}

.icon-bright {
  filter: brightness(0) saturate(100%) invert(90%) sepia(71%) saturate(0%) hue-rotate(199deg)
    brightness(104%) contrast(102%);
}

i.icon-big {
  background-size: 2rem 2rem;
  width: 2rem;
  height: 2rem;
}

.icon-discord {
  background-image: url('./icons/discord.svg');
}

.icon-smile {
  background-image: url('./icons/smile.svg');
}

.icon-sparkle {
  background-image: url('./icons/sparkle.svg');
}

.icon-check {
  background-image: url('./icons/check.svg');
}

.icon-copy {
  background-image: url('./icons/copy.svg');
}

.icon-chat {
  background-image: url('./icons/chat.svg');
}

.icon-mic {
  background-image: url('./icons/mic.svg');
}

.icon-mic-muted {
  background-image: url('./icons/mic-muted.svg');
}

.icon-add {
  background-image: url('./icons/add.svg');
}

.icon-cog {
  background-image: url('./icons/cog.svg');
}

.icon-door {
  background-image: url('./icons/door.svg');
}

.icon-group {
  background-image: url('./icons/group.svg');
}

.icon-hide {
  background-image: url('./icons/hide.svg');
}

.icon-info {
  background-image: url('./icons/info.svg');
}

.icon-invite {
  background-image: url('./icons/invite.svg');
}

.icon-isolate {
  background-image: url('./icons/isolate.svg');
}

.icon-leave-group {
  background-image: url('./icons/leave-group.svg');
}

.icon-pin {
  background-image: url('./icons/pin.svg');
}

.icon-rename {
  background-image: url('./icons/rename.svg');
}

.icon-room {
  background-image: url('./icons/room.svg');
}

.icon-screen {
  background-image: url('./icons/screen.svg');
}

.icon-no-screen {
  background-image: url('./icons/no-screen.svg');
}

.icon-show {
  background-image: url('./icons/show.svg');
}

.icon-speak {
  background-image: url('./icons/speak.svg');
}

.icon-unpin {
  background-image: url('./icons/unpin.svg');
}

.icon-write {
  background-image: url('./icons/write.svg');
}

.icon-up {
  background-image: url('./icons/up.svg');
}

.icon-down {
  background-image: url('./icons/down.svg');
}

.icon-light {
  background-image: url('./icons/light.svg');
}

.icon-upload {
  background-image: url('./icons/upload.svg');
}

.icon-remove {
  background-image: url('./icons/remove.svg');
}

.icon-keyboard {
  background-image: url('./icons/keyboard.svg');
}

.icon-windows {
  background-image: url('./icons/windows.svg');
}

.icon-unicorn {
  background-image: url('./icons/unicorn.svg');
}

.icon-camera {
  background-image: url('./icons/camera.svg');
}

.icon-no-camera {
  background-image: url('./icons/no-camera.svg');
}
